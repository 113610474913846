<template>
    <div class="p-t-5 p-l-5">
        <Divider dashed style="margin: 10px 0 5px 0;"><span class="divider-text">数据汇总</span></Divider>
        <div >
          <div class="m-t-10">
              <p class="title">结算公司</p>
             {{statisticsData.settlementName}}
          </div>
          <div class="m-t-10">
              <p class="title">供应商</p>
              {{statisticsData.supplierName}}
          </div>

        </div>
        <Row class=" m-t-10 p-t-5 p-b-5 ">
            <i-col span="24" class="p-b-10">
              <p class="title">总金额</p>
               <p class="text-16">{{formatMoney(statisticsData.totalAmount)}}</p>
            </i-col>
            <i-col span="24" class="p-b-10">
              <p class="title">任务数量</p>
               <p class="text-16">{{statisticsData.totalTaskCount}}</p>
            </i-col>
            <!-- <i-col span="12">

                <p class="title">已结算金额</p>
                {{formatMoney(statisticsData.settledAmount)}}
            </i-col>
            <i-col span="12">
                <p class="title">未结算金额</p>
                {{formatMoney(statisticsData.unsettledAmount)}}
            </i-col>
            <i-col span="12">
                <p class="title">已结算数量</p>
                {{statisticsData.settledQuantity}}
            </i-col>
            <i-col span="12">
                <p class="title">未结算数量</p>
                {{statisticsData.unsettledQuantity}}
            </i-col> -->
        </Row>

    </div>
</template>

<script>
import { getPlanDetailAmountStatistic } from '@/api/msp/settlement'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      query: {
        startDate: '',
        endDate: '',
        status: null,
        keyword: ''
      },
      statisticsData: {}
    }
  },
  created () {
    // this.initPageData()
  },
  methods: {
    initPageData () {
      getPlanDetailAmountStatistic(this.query).then(res => {
        this.statisticsData = res
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.workorderManageStore.beginUpdate
    },
    workOrderQuery () {
      return this.$store.state.workorderManageStore.workOrderQuery
    }
  },
  watch: {
    beginUpdate () {
      this.query.supplierCompanyId =
          this.workOrderQuery.supplierCompanyId === -1
            ? null
            : this.workOrderQuery.supplierCompanyId // 供应商

      this.query.settlementCompanyId =
        this.workOrderQuery.settlementCompanyId === -1
          ? null
          : this.workOrderQuery.settlementCompanyId // 结算公司
      this.query.startDate = this.workOrderQuery.startDate // 开始时间
      this.query.endDate = this.workOrderQuery.endDate // 结束时间

      this.query.timeType =
          this.workOrderQuery.timeType // 时间类型

      this.query.keyword = this.workOrderQuery.keyword // 关键字
      this.query.minCost = this.workOrderQuery.minCost // 最小计费金额
      this.query.maxCost = this.workOrderQuery.maxCost // 最大计费金额

      this.query.statuses = this.workOrderQuery.statuses

      this.query.taskTypes =
          this.workOrderQuery.taskTypes // 作业类型

      this.query.resourceTypes =
          this.workOrderQuery.resourceTypes // 资源类型
      this.query.adTypes = this.workOrderQuery.adTypes // 用途
      this.initPageData()
    }
  }
}
</script>
